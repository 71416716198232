import React, { useState } from "react";
import "./index.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import add from "../../images/add.png";
import glob from "../../images/glob.png";
import arrow_right from "../../images/arrow_right.png";
import payments from "../../images/payments.png";
import email from "../../images/email.png";
import { Button, Input } from "antd";

export default function Index() {
  const [activity, setActivity] = useState([
    {
      id: 1,
      link: "/",
      title: "Desert Safari",
    },
    {
      id: 2,
      link: "/",
      title: "Dhow Cruise",
    },
    {
      id: 3,
      link: "/",
      title: "City Tours",
    },
    {
      id: 4,
      link: "/",
      title: "Tour Package",
    },
  ]);
  const [support, setSupport] = useState([
    {
      id: 1,
      link: "/contact",
      title: "Contact Us",
    },
    {
      id: 2,
      link: "/terms",
      title: "Terms & Condition",
    },
    {
      id: 3,
      link: "/privacy",
      title: "Privacy",
    },
  ]);

  const [about, setAbout] = useState([
    {
      id: 1,
      link: "/about",
      title: "About Us",
    },
    {
      id: 2,
      link: "/career",
      title: "Career Page",
    },
  ]);

  return (
    <Container className="footer-main-container">
      <Row className="footer-header">
        <Col md={6}>
          <Row>
            <Col md={4}>
              <div className="quick_links_container">
                <Image src={add} className="add_icon" />
                <p className="quick_link_text">QUICK LINKS</p>
              </div>
            </Col>
            <Col md={4}>
              <p className="explore-text">Explore more categories</p>
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <p className="explore-text text-end">
            0123-456-324-54 | hello@falconoasis.com
          </p>
        </Col>
      </Row>
      <Row className="footer-primary-body">
        <Col md={4}>
          <span className="get-in-touch">Get in touch</span>
          <h3 className="adventure-calling">Adventures Calling You Guys!</h3>
        </Col>
        <Col md={4}></Col>
        <Col md={4} className="primary-body-right">
          <Image src={glob} className="globe-icon" />
          <div className="address-section">
            <span className="our-office-text">Our Offices</span>

            <div className="address-text">Dubai, UAE</div>
            <Image src={arrow_right} className="arrow-right-address" />
          </div>
        </Col>
      </Row>
      <Row className="footer-main-body">
        <Col md={8}>
          <Row>
            <Col md={4}>
              <h5 className="footer-links-title">Activities</h5>
              <ul className="footer_ul">
                {activity.map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.link} className="footer-li-links">
                        {item.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </Col>
            <Col md={4}>
              <h5 className="footer-links-title">Support</h5>
              <ul className="footer_ul">
                {support.map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.link} className="footer-li-links">
                        {item.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </Col>
            <Col md={4}>
              <h5 className="footer-links-title">About</h5>
              <ul className="footer_ul">
                {about.map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={item.link} className="footer-li-links">
                        {item.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </Col>
          </Row>
        </Col>
        <Col md={4} className="footer-main-body-contact-section">
          <br></br>
          <br></br>
          <br></br>
          <p className="get-in-touch">Get In Touch</p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h3 className="lets-talk">Let’s Talk</h3>
            <Image src={arrow_right} className="lets-talk-arrow" />
          </div>
          <div style={{ width: "100%" }}>
            <div className="email-box-container" style={{ marginTop: "20px" }}>
              <div className="email-news-letter-container">
                <Input
                  className="antd-email-input"
                  placeholder={"Enter your email"}
                  prefix={
                    <Image
                      src={email}
                      style={{ width: "15px", height: "10px" }}
                    />
                  }
                />
                <Button className="send-now-btn">Send Now!</Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="footer-primary-bottom">
        <Col md={6}>
          <span
            className="get-in-touch"
            style={{ color: "#000000", marginLeft: "0px" }}
          >
            Get in touch
          </span>
        </Col>
        <Col md={6} className="text-end">
          <span
            className="get-in-touch "
            style={{ color: "#000000", marginLeft: "0px" }}
          >
            Linkedin / Facebook / Instagram
          </span>
        </Col>
      </Row>
      <Row style={{ marginBottom: "100px" }}>
        <Col md={6}>
          <span
            className="get-in-touch"
            style={{ color: "#000000", marginLeft: "0px", fontSize: "12px" }}
          >
            2022-2024 © Aladdin Adventure Travel & Tours LLC
          </span>
        </Col>
        <Col md={6} className="text-end">
          <Image src={payments} className="payment-image" />
        </Col>
      </Row>
    </Container>
  );
}
