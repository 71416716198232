import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "./index.css";
import { falconApiCalling } from "../API/falconApiCalling";
import { Select, Space, Form, DatePicker, Button, Modal } from "antd";
import calender from "../../images/calender.png";
import Counter from "../Counter/index";
import { ToastContainer, toast } from "react-toastify";
import { child } from "firebase/database";
import moment from "moment";
import { BrowserView, MobileOnlyView, MobileView } from "react-device-detect";
import { Checkbox } from "antd";
import celebrate from "../../images/celebrate.gif";
export default function Index(props) {
  const [pid, setPid] = useState(window.location.pathname.split("/")[2]);
  const [sharingList, setsharingList] = useState([
    {
      label: "Sharing Transfer (deira)",
      value: "Sharing Transfer (deira)",
    },
  ]);
  const [privateList, setprivateList] = useState([
    {
      label: "5 Seater (up to 5 persons)",
      value: "5 Seater",
    },
    {
      label: "9 Seater (up to 9 persons)",
      value: "9 Seater",
    },
  ]);
  const [typeList, setTypeList] = useState([]);
  const [filteredType, setFilteredType] = useState([]);
  const [adult, setAdult] = useState(0);
  const [child, setChild] = useState(0);
  const [total, setTotal] = useState(0);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [transport, setTransport] = useState(null);
  const [product_name, setProduct_name] = useState(null);
  const [product_image, setProduct_image] = useState(null);
  const [privateTransport, setPrivateTransport] = useState([]);
  const [sharingTransport, setSharingTransport] = useState([]);
  const [showPrivate, setShowPrivate] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [transportAmount, setTransportAmount] = useState(0);
  const [childAmount, setChildAmount] = useState(0);
  const [adultAmount, setAdultAmount] = useState(0);
  const [transportType, setTransportType] = useState(null);
  const [minAmount, setMinAmount] = useState("");
  const [compAmount, setCompAmount] = useState("");
  const [showAddOn, setshowAddOn] = useState(false);
  const [addOnList, setAddOnList] = useState([]);
  const [selectedAddOn, setSelectedAddOn] = useState(null);
  const [showTransport, setShowTransport] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const calculate = () => {
    let add = 0;
    let adultP = adult * adultAmount;
    let childP = child * childAmount;
    let transport = transportAmount * adult + transportAmount * child;
    add = calculateAdd();
    let total = adultP + childP + transport + add;

    setTotalAmount(total);
    setTotal(adult + child);
    console.log("Adult", adult, "child", child);
  };

  const calculateAdd = () => {
    if (Array.isArray(selectedAddOn)) {
      const totalPeople = adult + child;
      return selectedAddOn.reduce((total, ticket) => {
        if (ticket.type === "Per Pax") {
          return total + totalPeople * ticket.price;
        } else {
          return total + ticket.price;
        }
      }, 0);
    } else {
      return 0;
    }
  };

  const getProductType = () => {
    let params = { action: "GET_PRODUCT_TYPE_LIST", pid: pid };
    falconApiCalling(params).then((res) => {
      if (Array.isArray) {
        setTypeList(res);
        let arr = res.map((item) => {
          return {
            label: item.name,
            value: item.id,
            adult: item.adult,
            child: item.child,
          };
        });
        setFilteredType(arr);
      }
    });
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
    setSelectedDate(dateString);
  };

  const onChangeAdult = () => {
    setAdult(adult + 1);
    setTotal(adult + 1 + child);
  };
  const onChangeChild = () => {
    setChild(child + 1);
    setTotal(adult + child + 1);
  };

  const downAdult = () => {
    if (adult === 0) {
    } else {
      setAdult(adult - 1);
      setTotal(adult - 1 + child);
    }
  };

  const downChild = () => {
    if (child === 0) {
    } else {
      setChild(child - 1);
      setTotal(adult + child - 1);
    }
  };

  const addToCart = () => {
    const data = {
      pid: pid,
      pname: product_name,
      image: product_image,
      adult: adult,
      child: child,
      total: total,
      type: selectedType,
      date: selectedDate,
      transport: transportAmount,
      transport_type: transportType,
      addOn: selectedAddOn,
    };
    let finalData = [data];
    let old = JSON.parse(localStorage.getItem("falcon_cart"));
    if (Array.isArray(old) && old != "null") {
      let available = old.filter((item) => item.pid === pid);
      if (available.length > 0) {
        toast.warning("Product already in cart");
      } else {
        let newArr = JSON.parse(localStorage.getItem("falcon_cart"));
        let newData = newArr.push(data);

        localStorage.setItem("falcon_cart", JSON.stringify(newArr));
        toast.success("Product Added to cart");
        window.location.href = "/cart";
      }
    } else {
      localStorage.setItem("falcon_cart", JSON.stringify(finalData));
      toast.success("Product Added to cart");
      window.location.href = "/cart";
    }
  };

  const getProductImages = () => {
    let params = { action: "GET_PRODUCT_IMAGES", pid: pid };
    falconApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        setProduct_image(res[0].original);
      }
    });
  };

  const getProductName = () => {
    let params = { action: "GET_PRODUCT_DETAIL", pid: pid };
    falconApiCalling(params).then((res) => {
      if (res.status === "success") {
        setProduct_name(res.product_name);
        setMinAmount(res.amount);
        setCompAmount(res.comp);
        props.myBottom(res.amount);
        let transport = res.show_transport;
        if (transport === "Yes") {
          setShowTransport(true);
        } else {
          setShowTransport(false);
        }
      }
    });
  };

  const getTransportPrivate = () => {
    let params = { action: "GET_PRODUCT_PRIVATE_TRANSPORT", pid: pid };
    falconApiCalling(params).then((res) => {
      console.log("res=====>", res);
      if (Array.isArray(res)) {
        setPrivateTransport(
          res.map((item) => {
            return {
              label: item.name,
              price: item.price,
              value: item.id,
            };
          })
        );
      }
    });
  };

  const getTransportSharing = () => {
    let params = { action: "GET_PRODUCT_SHARING_TRANSPORT", pid: pid };
    falconApiCalling(params).then((res) => {
      console.log("res=====>", res);
      if (Array.isArray(res)) {
        setSharingTransport(
          res.map((item) => {
            return {
              label: item.name,
              price: item.price,
              value: item.id,
            };
          })
        );
      }
    });
  };

  const disabledDate = (currentDate) => {
    // Disable dates before today
    return currentDate && currentDate < moment().startOf("day");
  };

  const getAddOnList = () => {
    let params = { action: "GET_PRODUCT_ADD_ON_LIST", pid: pid };
    falconApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        setshowAddOn(true);
        let arr = res.map((item) => {
          return {
            value: item.id,
            label: item.name,
            price: item.price,
            type: item.type,
          };
        });
        setAddOnList(arr);
      } else {
        setshowAddOn(false);
      }
    });
  };

  useEffect(() => {
    getProductImages();
  }, []);

  useEffect(() => {
    getProductName();
  }, []);

  useEffect(() => {
    getProductType();
  }, []);

  useEffect(() => {
    getTransportPrivate();
  }, []);

  useEffect(() => {
    getTransportSharing();
  }, []);

  const checkAvailabilty = () => {
    showModal();
  };

  const calculateDiscount = () => {
    let discount = (parseInt(minAmount) / parseInt(compAmount)) * 100;
    return 100 - discount.toFixed() + " %";
  };

  const onChangeAdd = (v) => {
    console.log("v========>", v);
  };

  useEffect(() => {
    calculate();
  }, [adult, child, selectedType, transport, selectedAddOn]);

  useEffect(() => {
    getAddOnList();
  }, []);

  return (
    <Container className="calculator_container" id="book_now">
      <Container className="price_container">
        <div style={{ padding: "0px" }}>
          <span className="max_price">AED {compAmount}/-</span>
          <Row>
            <Col md={5} style={{ padding: "0px" }}>
              <BrowserView>
                <h2 className="discounted_price">AED {minAmount}.00/-</h2>
              </BrowserView>
              <MobileView>
                <h2 className="discounted_price_left text-left">
                  AED {minAmount}.00/-
                </h2>
              </MobileView>
            </Col>
            <Col
              md={3}
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "left",
                padding: "0px",
              }}
            >
              <BrowserView>
                <span className="per_person">Per Person</span>
              </BrowserView>
              <MobileView>
                <span className="per_person_mobile">Per Person</span>
              </MobileView>
            </Col>
            <Col
              md={4}
              style={{
                padding: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="off_percentage">
                <span className="off_text">{calculateDiscount()} Off</span>
              </div>
            </Col>
          </Row>
          <span className="note_description">
            *Price based on selections below.
          </span>
        </div>
      </Container>
      <BrowserView>
        <Container className="form_container">
          <Form layout="vertical">
            <Form.Item label="Type" required tooltip="This is a required field">
              <Select
                placeholder={"Select Type"}
                options={filteredType}
                onChange={(v, i) => {
                  setSelectedType(i);
                  console.log("type=====>", i);
                  setAdultAmount(parseInt(i.adult));
                  setChildAmount(parseInt(i.child));
                }}
              />
            </Form.Item>
            <Form.Item
              label="Dates"
              required
              tooltip="This is a required field"
            >
              <DatePicker
                onChange={onChange}
                style={{ width: "100%" }}
                prefix={<Image src={calender} />}
                disabledDate={disabledDate}
              />
            </Form.Item>
            <Form.Item label="Adult">
              <Counter value={adult} add={onChangeAdult} minus={downAdult} />
            </Form.Item>
            <Form.Item label="Child">
              <Counter value={child} add={onChangeChild} minus={downChild} />
            </Form.Item>
            {showTransport ? (
              <Form.Item
                label="Transport Type"
                required
                tooltip="This is a required field"
              >
                <Select
                  placeholder={"Select Transportation"}
                  defaultValue={"Sharing Transport"}
                  options={[
                    {
                      label: "Sharing Transport",
                      value: "Sharing Transport",
                    },
                    {
                      label: "Private Transport",
                      value: "Private Transport",
                    },
                  ]}
                  onChange={(v) => {
                    setTransportAmount(0);
                    if (v === "Sharing Transport") {
                      setShowPrivate(false);
                    } else {
                      setShowPrivate(true);
                    }
                  }}
                />
              </Form.Item>
            ) : null}
            {showTransport ? (
              <>
                {showPrivate ? null : (
                  <Form.Item
                    label="Sharing Transportation"
                    required
                    tooltip="This is a required field"
                    disabled
                  >
                    <Select
                      placeholder={"Sharing Transportation"}
                      options={sharingTransport}
                      onChange={(v, i) => {
                        setTransportAmount(0);
                        let price = totalAmount - transportAmount;

                        let currTotal = parseInt(i.price) * total;
                        setTransportAmount(currTotal);
                        setTotalAmount(price + currTotal);
                        setTransportType(i.label);
                      }}
                    />
                  </Form.Item>
                )}
              </>
            ) : null}

            {showTransport ? (
              <>
                {showPrivate ? (
                  <Form.Item
                    label="Private Transportation"
                    required
                    tooltip="This is a required field"
                  >
                    <Select
                      placeholder={"Private Transportation"}
                      options={privateTransport}
                      onChange={(v, i) => {
                        setTransportAmount(0);
                        let price = totalAmount - transportAmount;

                        let currTotal = parseInt(i.price) * total;
                        setTransportAmount(currTotal);
                        setTotalAmount(price + currTotal);
                        setTransportType(i.label);
                      }}
                    />
                  </Form.Item>
                ) : null}
              </>
            ) : null}

            {showAddOn ? (
              <Form.Item label="Add On" required>
                <Select
                  placeholder={"Select Add Ons"}
                  mode={"multiple"}
                  options={addOnList}
                  onChange={(v, i) => {
                    console.log(i);
                    setSelectedAddOn(i);
                  }}
                />
              </Form.Item>
            ) : null}

            <Form.Item>
              <Button
                type="primary"
                className="check_availability"
                onClick={() => checkAvailabilty()}
              >
                Check Availability
              </Button>
            </Form.Item>
            {total > 0 ? (
              <>
                <Container>
                  <Row
                    className="mx-0"
                    style={{
                      minHeight: "50px",
                      marginBottom: "10px",
                    }}
                  >
                    <Col
                      style={{
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        {" "}
                        <strong>Total</strong>
                      </span>
                    </Col>
                    <Col
                      style={{
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        textAlign: "end",
                      }}
                    >
                      <span style={{ width: "100%" }}>
                        <strong>AED {totalAmount}</strong>
                      </span>
                    </Col>
                  </Row>
                </Container>
                <Form.Item>
                  <Button
                    type="primary"
                    className="check_availability"
                    onClick={() => addToCart()}
                  >
                    Add To Cart
                  </Button>
                </Form.Item>
              </>
            ) : null}
          </Form>
        </Container>
      </BrowserView>
      <MobileView>
        <Container className="form_container_mobile">
          <Form layout="vertical">
            <Form.Item label="Type" required tooltip="This is a required field">
              <Select
                placeholder={"Select Type"}
                options={filteredType}
                onChange={(v, i) => {
                  setSelectedType(i);
                  console.log("type=====>", i);
                  setAdultAmount(parseInt(i.adult));
                  setChildAmount(parseInt(i.child));
                }}
              />
            </Form.Item>
            <Form.Item
              label="Dates"
              required
              tooltip="This is a required field"
            >
              <DatePicker
                onChange={onChange}
                style={{ width: "100%" }}
                prefix={<Image src={calender} />}
                disabledDate={disabledDate}
              />
            </Form.Item>
            <Form.Item label="Adult">
              <Counter value={adult} add={onChangeAdult} minus={downAdult} />
            </Form.Item>
            <Form.Item label="Child">
              <Counter value={child} add={onChangeChild} minus={downChild} />
            </Form.Item>

            {showTransport ? (
              <Form.Item
                label="Transport Type"
                required
                tooltip="This is a required field"
              >
                <Select
                  placeholder={"Select Transportation"}
                  defaultValue={"Sharing Transport"}
                  options={[
                    {
                      label: "Sharing Transport",
                      value: "Sharing Transport",
                    },
                    {
                      label: "Private Transport",
                      value: "Private Transport",
                    },
                  ]}
                  onChange={(v) => {
                    setTransportAmount(0);
                    if (v === "Sharing Transport") {
                      setShowPrivate(false);
                    } else {
                      setShowPrivate(true);
                    }
                  }}
                />
              </Form.Item>
            ) : null}
            {showTransport ? (
              <>
                {showPrivate ? null : (
                  <Form.Item
                    label="Sharing Transportation"
                    required
                    tooltip="This is a required field"
                    disabled
                  >
                    <Select
                      placeholder={"Sharing Transportation"}
                      options={sharingTransport}
                      onChange={(v, i) => {
                        setTransportAmount(0);
                        let price = totalAmount - transportAmount;

                        let currTotal = parseInt(i.price) * total;
                        setTransportAmount(currTotal);
                        setTotalAmount(price + currTotal);
                        setTransportType(i.label);
                      }}
                    />
                  </Form.Item>
                )}
              </>
            ) : null}

            {showTransport ? (
              <>
                {showPrivate ? (
                  <Form.Item
                    label="Private Transportation"
                    required
                    tooltip="This is a required field"
                  >
                    <Select
                      placeholder={"Private Transportation"}
                      options={privateTransport}
                      onChange={(v, i) => {
                        setTransportAmount(0);
                        let price = totalAmount - transportAmount;

                        let currTotal = parseInt(i.price) * total;
                        setTransportAmount(currTotal);
                        setTotalAmount(price + currTotal);
                        setTransportType(i.label);
                      }}
                    />
                  </Form.Item>
                ) : null}
              </>
            ) : null}

            <Form.Item>
              <Button
                type="primary"
                className="check_availability"
                onClick={() => checkAvailabilty()}
              >
                Check Availability
              </Button>
            </Form.Item>
            {total > 0 ? (
              <>
                <Container>
                  <Row
                    className="mx-0"
                    style={{
                      height: "50px",
                      marginBottom: "10px",
                    }}
                  >
                    <Col
                      style={{
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        {" "}
                        <strong>Total</strong>
                      </span>
                    </Col>
                    <Col
                      style={{
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                        textAlign: "end",
                      }}
                    >
                      <span style={{ width: "100%" }}>
                        <strong>AED {totalAmount}</strong>
                      </span>
                    </Col>
                  </Row>
                </Container>
                <Form.Item>
                  <Button
                    type="primary"
                    className="check_availability"
                    onClick={() => addToCart()}
                  >
                    Add To Cart
                  </Button>
                </Form.Item>
              </>
            ) : null}
          </Form>
        </Container>
      </MobileView>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ position: "relative" }}
      >
        <div className="modal_bg">
          <Image src={celebrate} fluid className="modal_bg_image" />
          <div className="modal_top">
            <h3 className="congratulation_heading text-center">
              Congratulation's
            </h3>
            <p className="available_text text-center">
              This tour package is available, Please add product to cart
            </p>
          </div>
        </div>
      </Modal>
    </Container>
  );
}
